import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby'
import './menu.css'

const Menu = () => {
  const [dropdown, setDropdown] = useState(false)

  const toggle = () => setDropdown(!dropdown)

  useEffect(() => {
    if (dropdown) {
      document.getElementById('dropdown').style.top = '65px'
    } else {
      document.getElementById('dropdown').style.top = '-190px'
    }
  })

  return (
  <div>
  <nav className='main-nav'>
    <div className='landing'><Link to ='/' className='btn' style={{ textDecoration: 'none' }}>KST - Developer</Link></div>
  <div className='links'>
  <div className='menu'><button className='btn' style={{ outline: 'none' }} onClick={() => {toggle()}} >☰</button></div>
      <div className='desktop'>
        <div><Link to ='/about' className='btn' style={{ textDecoration: 'none' }}>About Me</Link></div>
        <div><Link to ='/skills' className='btn' style={{ textDecoration: 'none' }}>Tech Skills</Link></div>
        <div><Link to ='/projects' className='btn' style={{ textDecoration: 'none' }}>Projects</Link></div>
        <div><Link to ='/blog' className='btn' style={{ textDecoration: 'none' }}>Blog</Link></div>
        <div><Link to ='/contact' className='btn' style={{ textDecoration: 'none' }}>Contact</Link></div>
      </div>
  </div>
  </nav>
  <div id='dropdown'>
          <div><Link to ='/about' className='btn' style={{ textDecoration: 'none' }}>About Me</Link></div>
          <br/>
          <div><Link to ='/skills' className='btn' style={{ textDecoration: 'none' }}>Tech Skills</Link></div>
          <br/>
          <div><Link to ='/projects' className='btn' style={{ textDecoration: 'none' }}>Projects</Link></div>
          <br/>
          <div><Link to ='/blog' className='btn' style={{ textDecoration: 'none' }}>Blog</Link></div>
          <br/>
          <div><Link to ='/contact' className='btn' style={{ textDecoration: 'none' }}>Contact</Link></div>
          <br/>
          </div>
  </div>
  );
}
export default Menu;